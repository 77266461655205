import { FOG_40, WHITE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import { Ankle } from '../components/csma/ankle';
import { HeroNonConcierge } from '../components/csma/hero';
import { GreenBackgroundStrip } from '../components/csma/hero/elements';
import { HowItWorks } from '../components/csma/how-it-works';
import { RdcLandingPageLayout } from '../components/csma/layout';
import { RdcSection } from '../components/csma/layout/elements';
import { WhyWorkWithUs } from '../components/csma/why-work-with-us';
import { valuePropsNonConcierge } from '../data/content/csma/value-props';

export const RealtorCsmaNc = () => (
  <RdcLandingPageLayout>
    <GreenBackgroundStrip />
    <RdcSection backgroundColor={WHITE_100}>
      <HeroNonConcierge />
      <HowItWorks title="Next steps" valueProps={valuePropsNonConcierge} />
      <WhyWorkWithUs />
    </RdcSection>
    <RdcSection backgroundColor={FOG_40}>
      <Ankle />
    </RdcSection>
  </RdcLandingPageLayout>
);

export default RealtorCsmaNc;
